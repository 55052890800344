import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { Container, Row, Col, } from 'reactstrap';

import * as actions from '../store/actions';
import InputField from '../components/InputField';
import axios from 'axios';
import { BASE_URL } from '../common/constants';
import FormModal from '../components/FormModal';
import Berufsgruppen from '../hoc/Berufsgruppen';
import Mitgliedsarten from '../hoc/Mitgliedsarten';
import Loader from '../common/loader';
import Adresse from './Adresse';
import BevCard from '../components/BevCard';
import Kosten from './Kosten';
import OffenePosten from './offenePosten';


const styles = theme => ({
  root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  grid: {
      flexGrow: 1,
    },
});


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateInstitutionOptions: () => dispatch(actions.base.updateInstitutionOptionsSaga()),
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  }
}

const mapStateToProps = (state) => {
  return {
    options: state.base.institutionOptions,
    config: state.auth.config,
  }
}

class Mitglieder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countriesModal: false,
      berufsModal: false,
      mitgliedModal: false,
      kostenModal: false,
      institution: {},
    }
    this.saveInstitution = this.saveInstitution.bind(this);
  }



  componentDidMount() {
    this.props.updateInstitutionOptions();
    this.props.setTitle("Institution")
    if (this.props.match.params.id === "new") {
      this.setState({ institution: {}, loading: false} )
    } else {
      axios.get(BASE_URL+'institutionen/'+this.props.match.params.id+'/', this.props.config).then(res => {
        this.setState({ institution: res.data, loading: true })
      }).then(() => {
        this.setState({ loading: false}) 
      }).catch(err => {
        console.log("so nicht mein freund");
      });
      
    }
  }

  onChange = event => {
    let m = this.state.institution
    m[event.target.name] = event.target.value;
    this.setState({ institution: m });
  }

  onToggle = event => {
    let m = this.state.institution
    m[event.target.name] = !m[event.target.name]
    this.setState({ institution: m })
  }

  saveInstitution() {
    axios.put(BASE_URL+'institutionen/'+this.props.match.params.id+'/', this.state.institution, this.props.config).then(res => {
      console.log("es wurde gespeichert")
    });
  }

  changeStorndat = () => {
    let event = {target: {value: null, name: 'storndat' }}
    if (!this.state.institution.storndat) {
      let d = new Date()
      event.target.value = d.toISOString().split('T')[0]
    }
    this.onChange(event);
    console.log("event")
    console.log(event)
  }

  render() {
    const mgl = this.state.institution
    console.log(mgl);
    let aktivesInstitut = false
    if (!mgl.storndat) {
      aktivesInstitut = true
    }

    if (this.state.loading) {
      return <Loader />
    } else {
      return (<div className="row">
          <Container>
              <Button color="primary" onClick={this.saveInstitution}>Speichern</Button>
              <Button color="primary">Löschen</Button>
              <Divider />
              <InputField name="dsgvo" data={this.props.options.dsgvo} value={mgl.dsgvo} onChange={this.onToggle} />
              <InputField name="aktiv" data={{'type': 'boolean', 'label': 'Aktives Mitglied'}} value={aktivesInstitut} onChange={this.changeStorndat} />

            <BevCard key={1} title="Metadaten">
              <Row>
                <Col sm={4} style={{margin: '5px'}}>
                    <InputField name="mitgliedsnummer" data={this.props.options.mitgliedsnummer} value={mgl.mitgliedsnummer} onChange={this.onChange}/>

                    <InputField name="storndat" data={this.props.options.storndat} value={mgl.storndat} onChange={this.onChange}/>
                    <InputField name="mitgliedsart" data={this.props.options.mitgliedsart} value={mgl.mitgliedsart} onChange={this.onChange}/>
                    <FormModal open={this.state.mitgliedModal} title="Edit Mitgliedsarten" 
                        handleClose={() => {this.setState({mitgliedModal: !this.state.mitgliedModal}, this.props.updateInstitutionOptions() )}}>
                      <Mitgliedsarten />
                    </FormModal>
                    <p onClick={() => {this.setState({mitgliedModal: !this.state.mitgliedModal})}}>Mitgliedsarten bearbeiten</p>

                    <InputField name="kostenart" data={this.props.options.kostenart} value={mgl.kostenart} onChange={this.onChange}/>
                    <FormModal open={this.state.kostenModal} title="Edit Kostenarten" handleClose={() => {this.setState({kostenModal: !this.state.kostenModal}, this.props.updateInstitutionOptions())}}>
                      <Kosten />
                    </FormModal>
                    <p onClick={() => {this.setState({kostenModal: !this.state.kostenModal})}}>Kostenarten bearbeiten</p>
                    
                    <InputField name="versand" data={this.props.options.versand} value={mgl.versand} onChange={this.onChange}/>
                    <InputField name="sub" data={this.props.options.sub} value={mgl.sub} onChange={this.onChange}/>
                    <InputField name="heftanzahl" data={this.props.options.heftanzahl} value={mgl.heftanzahl} onChange={this.onChange}/>
                </Col>
                <Col sm={7} style={{margin: '5px'}}>
                  <InputField name="institution_name" data={this.props.options.institution_name} value={mgl.institution_name} onChange={this.onChange}/>
                  <InputField name="name2" data={this.props.options.name2} value={mgl.name2} onChange={this.onChange}/>
                  <InputField name="name3" data={this.props.options.name3} value={mgl.name3} onChange={this.onChange}/>
                  <InputField name="email" data={this.props.options.email} value={mgl.email} onChange={this.onChange}/>
                  <InputField name="berufsgruppe" data={this.props.options.berufsgruppe} value={mgl.berufsgruppe} onChange={this.onChange}/>
                  <FormModal open={this.state.berufsModal} title="Edit Berufsgruppen" handleClose={() => {this.setState({berufsModal: !this.state.berufsModal})}}>
                    <Berufsgruppen />
                  </FormModal>
                  <p onClick={() => {this.setState({berufsModal: !this.state.berufsModal})}}>Berufsgruppen bearbeiten</p>

                  
                  <InputField name="tel" data={this.props.options.tel} value={mgl.tel} onChange={this.onChange}/>
                  <InputField name="fax" data={this.props.options.fax} value={mgl.fax} onChange={this.onChange}/>
                  <InputField name="anmerkung" data={this.props.options.anmerkung} value={mgl.anmerkung} onChange={this.onChange}/>
                </Col>
            
              </Row>
            </BevCard>

            <BevCard key={3} title="Adressen" >
              <Row>
                <Col sm={4}>
                  <Adresse dataurl={mgl.wohnadresse} title={'Wohnadresse'} setUrl={(url) => this.setUrl(url, 'wohnadresse')} />
                </Col>
                <Col sm={4}>
                  <Adresse dataurl={mgl.lieferadresse} title={'Lieferadresse'} setUrl={(url) => this.setUrl(url, 'lieferadresse')} />
                </Col>
                <Col sm={4}>
                  <Adresse dataurl={mgl.rechnungsadresse} title={'Rechnungsadresse'} setUrl={(url) => this.setUrl(url, 'rechnungsadresse')} />
                </Col>
              </Row>
            </BevCard>

            <BevCard key={4} title="Mitgliedsbeiträge">
                <List>
                {/* <Button color="primary" onClick={this.addOffenePosten}>neuen Mitgliedsbeitrag anlegen</Button>
                <Button color="primary" onClick={this.createInvoice} target='_blank'>Zahlschein anzeigen</Button>
                <Button color="primary" onClick={() => (this.sendMail(BASE_URL+'rechnung/'+mgl.id+'/'))}>Zahlschein ausschicken</Button> */}

                  {mgl.offeneposten_set.map((op, i) => (
                    <OffenePosten key={i} url={op} mitglied={mgl.url} reload={this.reload}/>
                  ))}
                </List>
              </BevCard>
 
          </Container>}
        </div>)
    }
  }
}

Mitglieder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Mitglieder));
