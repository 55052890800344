import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import * as constants from '../common/constants';
import { Container } from 'reactstrap';
import BevTable from '../components/Table';
import { toast } from 'react-toastify';


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (title) => dispatch(actions.base.setTitle(title)),
  }
}


const mapStateToProps = (state) => {
  return {
    config: state.auth.config,
    offenePosten: state.base.offenePostenOptions,
  }
}

class Mitgliedsbeitraege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      members: false,
      offset: 0,
      pagination: 50,
      wer: '',
      aktive: true,
    }
  }

  componentDidMount() {
    this.props.setTitle("Mitgliedsbeitraege")
  }

  addOffenePosten = () => {
    this.props.history.push('/offeneposten/new')
  }


  columns =  [
      {name: "mnr", align: "left"},
      // {name: "id", align: "left"},
      {name: "description", align: "left"},
      {name: "name", align: "left"},
      {name: "offen", align: "left"},
      {name: "zahlung", align: "left"},
      {name: "bezahlt", align: "left", type: 'boolean'},
  ];

  getSwitchBack = (event) => {
    console.log("event")
    console.log(event)
  }

  render() {
    return <Container>
              <BevTable titel="Mitgliedsbeitraege" 
                url={constants.BASE_URL + "offeneposten/"} 
                detailUrl='/mitglied/'
                location={this.props.location}
                showNewButton={false}
                newText='Mitgliedsbeitrag anlegen'
                new={this.addOffenePosten}
                searchFieldName='namefilter' 
                config={this.props.config}
                switchButton={true}
                switchText={'nur offene Beiträge anzeigen'}
                switchUrl='offen=True'
                deleteField='bezahlt'
                getSwitchBack={this.getSwitchBack}
                columns={this.columns} />
            </Container>
  }

}



Mitgliedsbeitraege.defaultProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Mitgliedsbeitraege);
