import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import StartSeite from './StartSeite';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ovgStore from './store'; 

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css'; 


const app = (
    <Provider store={ovgStore}>
      <BrowserRouter>
        <StartSeite />
      </BrowserRouter>
    </Provider>
)


ReactDOM.render(app, document.getElementById('root'));

// registerServiceWorker();
navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for(let registration of registrations) {
   registration.unregister()
 } })
