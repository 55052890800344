import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../common/constants';
import Loader from '../common/loader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import * as actions from '../store/actions';
import { connect } from 'react-redux';
import FormModal from '../components/FormModal';
import Form from '../components/Form';



const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAdresse: () => dispatch(actions.base.updateAdresseOptions()),
  }
}


const mapStateToProps = (state) => {
  return {
    countries: state.base.countries,
    countriesOptions: state.base.countriesOptions,
    adresseOptions: state.base.adresseOptions,
    config: state.auth.config,

  }
}



class Adresse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      adresse: false,
      new: false,
      modal: false,
    }
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    if (!this.props.dataurl) {
     this.setState({ adresse: {}, new: true, loading: false})
    } else {
      axios.get(this.props.dataurl, this.props.config).then(res => {
        this.setState({ adresse: res.data, loading: false })
      }).catch(err => {
        console.log(err)
      });
    }
  }

  closeModal = () => {
    this.setState({ modal: false, })
  }

  formChange = event => {
    console.log(event.target.name);
    console.log(event.target.value);
    let adr = this.state.adresse
    adr[event.target.name] = event.target.value;
    this.setState(adr)
    console.log(this.state.adresse)
  }

  openModal = () => {
    if (this.state.new) {
      axios.post(BASE_URL+'adresse/', {}, this.props.config).then(res => {
        this.setState({ adresse: res.data, modal: true, new: false, });
        this.props.setUrl(res.data.url);
      })
    } else {
      this.setState({ modal: true, })
    }
  }
  
  render() {
    const adr = this.state.adresse
    return (<div className="row">
        { this.state.loading ? <Loader /> : <div><Card style={{marginTop:'10px', cursor: 'pointer'}} onClick={this.openModal} >
            <CardHeader title={this.props.title} />
            <CardContent>
              {this.state.new ? <p><b>Adresse anlegen</b></p> : <div>
                  <p>Straße: {adr.strasse}</p>
                  <p>PLZ: {adr.plz}</p>
                  <p>Ort: {adr.ort}</p>
                  { adr.country === null ? <p>Country: -</p> : <p>Country: {this.props.countries.filter(el => {return el.url === adr.country})[0].land}</p>}
                </div>
              }
            </CardContent>
          </Card>
          <FormModal open={this.state.modal} title="Edit Adresse" handleClose={this.closeModal}>
            <Form values={adr}
              options={this.props.adresseOptions}
              handleCancel={this.closeModal}
              onChange={this.formChange} postSave={adr => this.props.setUrl(adr.url) } />          
          </FormModal>
        </div>
        }
        </div>)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Adresse);
