import React, { Component } from 'react';
import { connect } from 'react-redux';



const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    undefined
  }
}


const mapStateToProps = (state) => {
  return {
    title: state.base.title,
  }
}


class Headerline extends Component {

  render() {
    return ( <div className="row" style={{ backgroundColor: '#eee', padding: '20px', borderTop: '1px solid grey', borderBottom: '1px solid grey', marginRight: '0px'}}>
              <div className="container">
                <h3 style={{borderBottom: '5px solid', borderBottomColor: '#349900', display: 'inline', paddingBottom: '15px'}}>{this.props.title}</h3>
              </div>
            </div>
    )}
 }


 export default connect(mapStateToProps, mapDispatchToProps)(Headerline)
