import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth';
import baseReducer from './base';

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['isLoading'],
}


const rootReducer = combineReducers({
    base: baseReducer,
    auth: persistReducer(authPersistConfig, authReducer),
});


export default rootReducer;
