import * as actionTypes from './actionTypes';
import axios from 'axios';
import { BASE_URL } from '../../common/constants';


export const updateMemberOptionsSaga  = () => ({ type: actionTypes.UPDATE_FOR_MEMBER })
export const updateMemberOptions = (value) => ({ type: actionTypes.UPDATE_MEMBER_OPTIONS, options: value })

export const setCountriesLoaded = (val) => ({ type: actionTypes.SET_COUNTRIES_LOADED, value: val })
export const updateCountriesSaga = () => ({ type: actionTypes.UPDATE_COUNTRIES })
export const setCountries = (cs) => ({ type: actionTypes.SET_COUNTRIES, cs: cs, })
export const setCountriesOptions = (options) => ({ type: actionTypes.UPDATE_COUNTRIES_OPTIONS, options: options, })

export const updateVortragsorteSaga = () => ({ type: actionTypes.UPDATE_FOR_VORTRAG })
export const setVortragLoaded = (val) => ({ type: actionTypes.SET_VORTRAG_LOADED, value: val })
export const setVortragsorte = (val) => ({ type: actionTypes.SET_VORTRAGSORTE, value: val, })

export const setAdresseOptions = (val) => ({ type: actionTypes.UPDATE_ADRESSE_OPTIONS, value: val, })
export const updateOffenePostenOptions = () => ({ type: actionTypes.UPDATE_OFFENE_POSTEN_OPTIONS })
export const setOffenePostenOptions = (val) => ({ type: actionTypes.SET_OFFENE_POSTEN_OPTIONS, value: val, })

export const updateInstitutionOptionsSaga = () => ({ type: actionTypes.UPDATE_FOR_INSTITUTIONS })
export const setInstitutionOptions = (val) => ({ type: actionTypes.UPDATE_INSTITUTION_OPTIONS, value: val, })

export const updateAbonnentOptionsSaga = () => ({ type: actionTypes.UPDATE_FOR_ABONNENT })
export const setAbonnentOptions = (val) => ({ type: actionTypes.UPDATE_ABONNENT_OPTIONS, value: val, })

export const updateAboHeftOptionsSaga = () => ({ type: actionTypes.UPDATE_FOR_ABOHEFT })
export const setAboHeftOptions = (val) => ({ type: actionTypes.UPDATE_ABOHEFT_OPTIONS, value: val, })

export const updateOffeneAboPostenOptionsSaga = () => ({ type: actionTypes.UPDATE_FOR_OFFENE_ABO_POSTEN })
export const setOffeneAboPostenOptions = (val) => ({ type: actionTypes.UPDATE_OFFENE_ABO_POSTEN_OPTIONS, value: val})


export const updateKostenOptionsSaga = () => ({ type: actionTypes.UPDATE_FOR_KOSTEN })
export const setKostenOptions = (val) => ({ type: actionTypes.UPDATE_KOSTEN_OPTIONS, value: val })

export const setZahlscheinText = (val) => ({ type: actionTypes.SET_ZAHLSCHEIN_TEXT, value: val })
export const setEmailText = (val) => ({ type: actionTypes.SET_EMAIL_TEXT, value: val })


// export const setInstitutionOptions = (val) => ({ type: actionTypes.UPDATE_INSTITUTION_OPTIONS, value: val, })




// return dispatch => {
//     axios.options(BASE_URL+'institutionen/').then(res => {
//       let options = res.data.actions.POST;
//       dispatch(updateKeyValue(actionTypes.UPDATE_INSTITUTION_OPTIONS, options))
//       // return { type: actionTypes.UPDATE_ADRESSE_OPTIONS, options: options }
//     });
//   }
// }









export const baseSetProgress = (value) => {
  return { type: actionTypes.BASE_SET_PROGRESS, value: value, }
}

export const setMember = (id) => {
  return { type: actionTypes.SET_MEMBER, id: id, }
}


export const setBerufe = (cs) => {
  return { type: actionTypes.SET_BERUFE, cs: cs, }
}

export const setTitle = (title) => {
  return { type: actionTypes.SET_TITLE, title: title, }
}

export const updateMembers = (members) => {
  return { type: actionTypes.UPDATE_MEMBER_LIST, members: members }
}

export const updateMemberOptions2 = (value) => {
  return { type: actionTypes.UPDATE_MEMBER_OPTIONS, options: value }
}



export const updateKeyValue = (key, value) => {
  return { type: key, value: value }
}



export const updateAbonnentOptions = () => {
  return dispatch => {
    axios.options(BASE_URL+'abonnent/').then(res => {
      let options = res.data.actions.POST;
      dispatch(updateKeyValue(actionTypes.UPDATE_ABONNENT_OPTIONS, options))
    });
  }
}


export const updateAboHeftOptions = () => {
  return dispatch => {
    axios.options(BASE_URL + 'aboeft/').then(res => {
      let options = res.data.actions.POST;
      dispatch(updateKeyValue(actionTypes.UPDATE_ABOHEFT_OPTIONS, options))
    })
  }
}


export const updateUserDetailsOptions = () => {
  console.log("da waeren wir also");
  return dispatch => {
    axios.options(BASE_URL+'users/').then(res => {
      let options = res.data.actions.POST;
      dispatch(updateKeyValue(actionTypes.UPDATE_USER_DETAILS_OPTIONS, options))
    });
  }
}


export const updateMitartOptions = () => {
  return dispatch => {
    axios.options(BASE_URL+'mitgliedsart/').then(res => {
      let options = res.data.actions.POST;
      dispatch(updateKeyValue(actionTypes.UPDATE_MITART_OPTIONS, options))
    });
  }
}

export const updateBerufeOptions = () => {
  return dispatch => {
    axios.options(BASE_URL+'berufe/').then(res => {
      let options = res.data.actions.POST;
      dispatch(updateKeyValue(actionTypes.UPDATE_BERUFE_OPTIONS, options))
    });
  }
}




const updateMember2 = (member) => {
  return { type: actionTypes.UPDATE_MEMBER, member: member }
}


export const updateMember = (member) => {
  return dispatch => {
    dispatch(updateMember2(member));
    if (member.first_name) {
      dispatch(setTitle(member.first_name+" "+member.last_name+" (#"+member.mitgliedsnummer+")"));
    } else {
      dispatch(setTitle("Neues Mitglied"))
    }
  }
}



export const updateMemberList = () => {
  return dispatch => {
    axios.get(BASE_URL+'vereinsmitglieder/').then(res => {
      const members = res.data;
      dispatch(updateMembers(members));
    }).catch(err => {
      console.log("so nicht mein freund");
    });

  }
}


export const updateBerufe = () => {
  return dispatch => {
    axios.get(BASE_URL+'berufe/').then(res => {
      const berufe = res.data;
      dispatch( {type: actionTypes.UPDATE_BERUFE, berufe: berufe} )
    }).catch(err => {
      console.log("so nicht mein freund");
    });
    axios.options(BASE_URL+'berufe/').then(res => {
      const options = res.data.actions.POST;
      dispatch({type: actionTypes.UPDATE_BERUFE_OPTIONS, options: options});
    });
  }
}
