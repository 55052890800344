import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    user: {
      firstname: 'Philipp',
      lastname: 'Mitterschiffthaler',
      username: 'philipp MST',
      organisation: 'BEV',
      roles: ['OVG_Admin', 'OVG_Manager'],
    },
    title: 'Dashboard',
    progress: false,
    members: [],
    member: {},
    memberOptions: [],
    countries: [],
    countriesOptions: [],
    countriesLoaded: false,

    berufe: [],
    berufeOptions: [],
    adresseOptions: [],
    institutionOptions: [],
    abonnentOptions: [],
    aboheftOptions: [],
    offenePostenOptions: [],
    offeneAboPostenOptions: [],

    vortragLoaded: false,
    vortragsorte: [],

    kostenOptions: [],
    mitartOptions: [],
    userDetailsOptions: [],
    
    zahlscheinText: 'Keine Neuigkeiten',
    emailText: 'Hi!',
}



const setMember = (state, action) => {
    let m = {}
    console.log(action.id);
    if (action.id !== 'new') {
      m = state.members[action.id]
    }
    return updateObject( state, { member: m });
}




const reducer = ( state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SET_MEMBER: return setMember( state, action );
        case actionTypes.SET_TITLE: return updateObject( state, { title: action.title } );
        case actionTypes.SET_COUNTRIES: return updateObject( state, { countries: [...action.cs] } );
        case actionTypes.SET_BERUFE: return updateObject( state, { berufe: [...action.cs] } );
        case actionTypes.SET_VORTRAGSORTE: return updateObject( state, { vortragsorte: [...action.value] } );
        case actionTypes.BASE_SET_PROGRESS: return updateObject( state, { progress: action.value } );

        case actionTypes.UPDATE_ABOHEFT_OPTIONS: return updateObject( state, { aboheftOptions: action.value } );
        case actionTypes.UPDATE_OFFENE_ABO_POSTEN_OPTIONS: return updateObject( state, { offeneAboPostenOptions: action.value })
        case actionTypes.UPDATE_ABONNENT_OPTIONS: return updateObject( state, { abonnentOptions: action.value } );
        case actionTypes.UPDATE_ADRESSE_OPTIONS: return updateObject( state, { adresseOptions: action.value } );
        case actionTypes.UPDATE_BERUFE: return updateObject( state, { berufe: action.berufe } );
        case actionTypes.UPDATE_BERUFE_OPTIONS: return updateObject( state, { berufeOptions: action.value } );
        case actionTypes.UPDATE_COUNTRIES_OPTIONS: return updateObject( state, { countriesOptions: action.options } );
        case actionTypes.SET_COUNTRIES_LOADED: return updateObject( state, { countriesLoaded: action.value } );
        case actionTypes.SET_OFFENE_POSTEN_OPTIONS: return updateObject( state, { offenePostenOptions: action.value } );
        
        case actionTypes.SET_VORTRAG_LOADED: return updateObject( state, { vortragLoaded: action.value } );

        case actionTypes.UPDATE_INSTITUTION_OPTIONS: return updateObject( state, { institutionOptions: action.value } );
        case actionTypes.UPDATE_KOSTEN_OPTIONS: return updateObject( state, { kostenOptions: action.value } );
        case actionTypes.UPDATE_MEMBER_LIST: return updateObject( state, { members: action.members } );
        case actionTypes.UPDATE_MEMBER_OPTIONS: return updateObject( state, {memberOptions: action.options } ); 
        case actionTypes.UPDATE_MEMBER: return updateObject( state, { member: {...action.member} } );
        case actionTypes.UPDATE_MITART_OPTIONS: return updateObject( state, { mitartOptions: action.value } );
        case actionTypes.UPDATE_USER_DETAILS_OPTIONS: return updateObject( state, { userDetailsOptions: action.value } );

        case actionTypes.SET_ZAHLSCHEIN_TEXT: return updateObject( state, { zahlscheinText: action.value } );
        case actionTypes.SET_EMAIL_TEXT: return updateObject( state, { emailText: action.value } );
        default:
            return state;
    }
}

export default reducer;
