import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../store/actions';
import { BASE_URL } from '../common/constants';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Container, Row, Col, } from 'reactstrap';
import SearchBar from '../components/SearchBar';
import { toast } from 'react-toastify';

const styles = theme => ({
  root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  }
}


const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    config: state.auth.config,
  }
}




class Jubilare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jubilare: ['mi'],
      jahr: new Date().getFullYear(),
    }
  }

  JubilareMonth = (jubilare, month) => {
    return <List>
    {jubilare.filter(el => {return el.monat === month}).map((p,i) => (
      <ListItem>
        <ListItemIcon><Avatar>{p.alter}</Avatar></ListItemIcon>
        <ListItemText primary={p.first_name+" "+p.last_name} secondary={p.gebdat} />
      </ListItem>
      ))}
    </List>
  }

  loadJubilare = () => {
    axios.get(BASE_URL + 'dashboard?jahr=' + this.state.jahr).then(res => {
    console.log(res.data);
    this.setState({
        loading: false,
        jubilare: res.data.jubilare,
      });
    })
    this.props.setTitle("Jubilare " + this.state.jahr)
  }

  componentDidMount() {
    this.loadJubilare();
  }

  getpdf = () => {
    axios.get(BASE_URL + 'vereinsmitglieder/jubilare_pdf/?jahr='+this.state.jahr, this.props.config).then(res => {
      const file = new Blob([res.data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL)
    }).catch(err => {
      toast.error(err.response.data);
    })
  }

  getcsv = () => {
    axios.get(BASE_URL + 'vereinsmitglieder/jubilare_csv/?jahr='+this.state.jahr, this.props.config).then(res => {
      const file = new Blob([res.data], {type: 'text/csv'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL)
    }).catch(err => {
      toast.error(err.response.data);
    })
  }


  render() {
    return <Container>
      <SearchBar value={this.state.jahr} 
        help_text={"Durch eingabe der Jahreszahl kann nach den Jubilaren des Jahres gesucht werden."}
        label={"Jahreszahl eingeben"} load={this.loadJubilare} 
        changeValue={(e) => {this.setState({ jahr: e.target.value })}} />

      <Button onClick={this.getpdf}>PDF erzeugen</Button>
      <Button onClick={this.getcsv}>CSV erzeugen</Button>
        
      { this.props.loading ? <h2>Daten werden geladen...</h2> : <Container>
        <Row>
          <Col sm={4}><h3>Jänner</h3>{this.JubilareMonth(this.state.jubilare, 1)}</Col>
          <Col sm={4}><h3>Februar</h3>{this.JubilareMonth(this.state.jubilare, 2)}</Col>
          <Col sm={4}><h3>März</h3>{this.JubilareMonth(this.state.jubilare, 3)}</Col>
          <Col sm={4}><h3>April</h3>{this.JubilareMonth(this.state.jubilare, 4)}</Col>
          <Col sm={4}><h3>Mai</h3>{this.JubilareMonth(this.state.jubilare, 5)}</Col>
          <Col sm={4}><h3>Juni</h3>{this.JubilareMonth(this.state.jubilare, 6)}</Col>
          <Col sm={4}><h3>Juli</h3>{this.JubilareMonth(this.state.jubilare, 7)}</Col>
          <Col sm={4}><h3>August</h3>{this.JubilareMonth(this.state.jubilare, 8)}</Col>
          <Col sm={4}><h3>September</h3>{this.JubilareMonth(this.state.jubilare, 9)}</Col>
          <Col sm={4}><h3>Oktober</h3>{this.JubilareMonth(this.state.jubilare, 10)}</Col>
          <Col sm={4}><h3>November</h3>{this.JubilareMonth(this.state.jubilare, 11)}</Col>
          <Col sm={4}><h3>Dezember</h3>{this.JubilareMonth(this.state.jubilare, 12)}</Col>
        </Row>
      </Container>
      }
    </Container>
  }
}

Jubilare.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Jubilare))
