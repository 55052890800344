import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    cursor: 'pointer',
  },
});


class DashPanel extends Component {
  constructor(props) {
    super(props);
    this.goto=this.goto.bind(this);
  }

  goto = (e) => {
    this.props.history.push(this.props.link);
  }

  render() {
    const { classes } = this.props;

    return (<div>
        <Paper className={classes.root} elevation={1} onClick={this.goto}>
          <h2>{this.props.value}</h2>
          <p>{this.props.text}</p>
        </Paper>
      </div>
    )}
 }


 DashPanel.propTypes = {
   classes: PropTypes.object.isRequired,
 };

 export default withRouter(withStyles(styles)(DashPanel));
