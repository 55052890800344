import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import * as actions from '../store/actions';
import { Container, Row, Col, } from 'reactstrap';
import InputField from '../components/InputField';
import axios from 'axios';
import { BASE_URL } from '../common/constants';
import Loader from '../common/loader';
import Adresse from './Adresse';
import AboHeft from './AboHeft';
import BevCard from '../components/BevCard';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

const styles = theme => ({
  root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  grid: {
      flexGrow: 1,
    },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAbonnentOptions: () => dispatch(actions.base.updateAbonnentOptionsSaga()),
    // updateAboHeftOptions: () => dispatch(actions.base.updateAboHeftOptions()),
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  }
}


const mapStateToProps = (state) => {
  return {
    abonnent: state.base.abonnent,
    options: state.base.abonnentOptions,
    config: state.auth.config,
  }
}

class Abonnent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countriesModal: false,
      berufsModal: false,
      abonnent: {},
    }
    this.saveAbonnent = this.saveAbonnent.bind(this);
    this.deleteAbonnent = this.deleteAbonnent.bind(this);
    this.addAboHeft = this.addAboHeft.bind(this);
  }



  componentDidMount() {
    // this.props.updateAboHeftOptions();
    this.props.updateAbonnentOptions();

    if (this.props.match.params.id === "new") {
      this.setState({ abonnent: {}, loading: false})
    } else {
      axios.get(BASE_URL+'abonnent/'+this.props.match.params.id+'/', this.props.config).then(res => {
        this.setState({ abonnent: res.data, loading: true })
        this.props.setTitle("Abonnent "+res.data.name)

      }).then(() => {this.setState({ loading: false}) }).catch(err => {
        console.log("so nicht mein freund");
      });
      
    }
  }

  createInvoice = () => {
    let url = BASE_URL+'abonnent/'+this.state.abonnent.id+'/create_invoice/';
    axios.get(url, this.props.config).then(res => {
      const file = new Blob([res.data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL)
    })
  }

  onChange = event => {
    let m = this.state.abonnent
    m[event.target.name] = event.target.value;
    this.setState({ abonnent: m });
  }

  onToggle = event => {
    let m = this.state.abonnent
    m[event.target.name] = !m[event.target.name]
    this.setState({ abonnent: m })
  }

  saveAbonnent() {
    axios.put(this.state.abonnent.url, this.state.abonnent, this.props.config).then(res => {
      console.log("es wurde gespeichert")
    });
  }

  deleteAbonnent() {
    axios.delete(this.state.abonnent.url, this.props.config).then(res => {
      this.props.history.push('/')
    })
  }

  reload = () => {
    console.log("reload wird ausgefuehrt")
    let m = this.state.abonnent;
    axios.get(this.state.abonnent.url, this.props.config).then(res => {
      m.aboheft_set = res.data.aboheft_set;
      this.setState({ abonnent: m })
    })
  }

  addAboHeft = () => {
    let m = this.state.abonnent
    axios.get(this.state.abonnent.url+ 'add_aboheft/', this.props.config).then(res => {
      toast.success(res.data)
      this.reload()
    })
  }


  render() {
    const mgl = this.state.abonnent
    console.log(mgl);
    if (this.state.loading) {
      return <Loader />
    } else {
      return (<div className="row">
          <Container>
              <Button color="primary" onClick={this.saveAbonnent}>Speichern</Button>
              <Button color="primary" onClick={this.deleteAbonnent}>Löschen</Button>
              <Divider />
              <InputField name="dsgvo" data={this.props.options.dsgvo} value={mgl.dsgvo} onChange={this.onToggle} />

            <BevCard title="Abodaten" open={true}>
              <Row>
                <Col sm={2} style={{margin: '5px'}}>
                    <InputField name="kundennummer" data={this.props.options.kundennummer} value={mgl.kundennummer} onChange={this.onChange}/>
                    <InputField name="uid" data={this.props.options.uid} value={mgl.uid} onChange={this.onChange}/>
                    <InputField name="prozent" data={this.props.options.prozent} value={mgl.prozent} onChange={this.onChange}/>
                    <InputField name="rechnungsanzahl" data={this.props.options.rechnungsanzahl} value={mgl.rechnungsanzahl} onChange={this.onChange}/>
                </Col>
                <Col sm={6} style={{margin: '5px'}}>
                  <InputField name="titel" data={this.props.options.titel} value={mgl.titel} onChange={this.onChange}/>
                  <InputField name="name" data={this.props.options.name} value={mgl.name} onChange={this.onChange}/>
                  <InputField name="name2" data={this.props.options.name2} value={mgl.name2} onChange={this.onChange}/>
                  <InputField name="name3" data={this.props.options.name3} value={mgl.name3} onChange={this.onChange}/>
                  <InputField name="email" data={this.props.options.email} value={mgl.email} onChange={this.onChange}/>

                  <InputField name="tel" data={this.props.options.tel} value={mgl.tel} onChange={this.onChange}/>
                  <InputField name="fax" data={this.props.options.fax} value={mgl.fax} onChange={this.onChange}/>
                </Col>
                <Col sm={3} style={{margin: '5px'}}>
                  <Adresse dataurl={mgl.rechnungsanschrift} title={'Rechnungsadresse'} />
                  <Button color="primary" onClick={this.createInvoice} target='_blank'>Rechnung anzeigen</Button>

                </Col>
              </Row>
            </BevCard>

            <BevCard title="AboHefte">
              <List>
                <Button color="primary" onClick={this.addAboHeft}>neues AboHeft</Button>
                { this.props.match.params.id !== "new" && mgl.aboheft_set.map(abo => (
                  <div>
                      <ListItem button component={Link} to={"/aboheft/" + abo.id} key={abo.url} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar style={abo.aboende ? {backgroundColor: 'red'} : {backgroundColor: 'green'}}>{abo.abonummer}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={abo.nachname}
                        secondary={
                          <React.Fragment>
                            <Typography component="span"  color="textPrimary">
                              {abo.heftanzahl + ' Hefte'}
                            </Typography>
                            {abo.plz + ' ' + abo.ort}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </div>
                ))}


              </List>
            </BevCard>
          </Container>}
        </div>)
    }
  }
}

Abonnent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Abonnent));
