import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import BevCard from '../components/BevCard';
import * as actions from '../store/actions';
import { Container, Row, Col, Alert } from 'reactstrap';
import InputField from '../components/InputField';
import axios from 'axios';
import FormModal from '../components/FormModal';
import Berufsgruppen from '../hoc/Berufsgruppen';
import Mitgliedsarten from '../hoc/Mitgliedsarten';
import Loader from '../common/loader';
import Adresse from './Adresse';
import Kosten from './Kosten';
import OffenePosten from './offenePosten';
import { BASE_URL } from '../common/constants';
import { toast } from 'react-toastify';


const styles = theme => ({
  root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  grid: {
      flexGrow: 1,
    },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },


});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateMember: (m,t) => dispatch(actions.base.updateMember(m)),
    updateMemberOptions: () => dispatch(actions.base.updateMemberOptionsSaga()),
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  }
}


const mapStateToProps = (state) => {
  return {
    members: state.base.members,
    member: state.base.member,
    options: state.base.memberOptions,
    vortragsorte: state.base.vortragsorte,
    config: state.auth.config,
    user: state.auth.user,
    countriesLoaded: state.base.countriesLoaded,
    vortragLoaded: state.base.vortragLoaded,
    zahlscheinText: state.base.zahlscheinText,
    emailText: state.base.emailText,
  }
}

class Mitglieder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countriesModal: false,
      berufsModal: false,
      member: {},
      new: false,
      errors: {},
    }
    this.saveMember = this.saveMember.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.changeVortrag = this.changeVortrag.bind(this);
    this.setUrl = this.setUrl.bind(this);
  }



  componentDidMount() {
    this.props.updateMemberOptions();
 
    if (this.props.match.params.id === "new") {
      this.setState({ member: {
        vortrag: [],
        offeneposten_set: [],
      }, new: true, loading: false})

    } else {
      axios.get(BASE_URL+'vereinsmitglieder/'+this.props.match.params.id+'/', this.props.config).then(res => {
        this.props.setTitle(res.data.first_name + " " + res.data.last_name);
        this.setState({ member: res.data, loading: true });
      }).then(() => {this.setState({ loading: false}) }).catch(err => {
        console.log("so nicht mein freund");
      });
      
    }
  }

  onChange = event => {
    let m = this.state.member
    m[event.target.name] = event.target.value;
    if (event.target.name === 'email') {
      m.username = event.target.value;
    }
    this.setState({ member: m });
  }

  createInvoice = () => {
    let url = BASE_URL+'vereinsmitglieder/'+this.state.member.id+'/create_invoice/';
    axios.post(url, {zahlscheinText: this.props.zahlscheinText}, this.props.config).then(res => {
      const file = new Blob([res.data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL)
    }).catch(err => {
      console.log(JSON.stringify(err))
      toast.warn(err.response.data);
    })
  }

  sendMail = () => {
    let url = BASE_URL+'vereinsmitglieder/'+this.state.member.id+'/send_mail/';
    let data = {
      zahlscheinText: this.props.zahlscheinText,
      emailText: this.props.emailText,
    }
    axios.post(url, data, this.props.config).then(res => {
      toast.success("Email wurde verschickt");
    }).catch(err => {
      toast.error("Email konnte nicht verschickt werden.")
    });
  }


  onToggle = event => {
    let m = this.state.member
    m[event.target.name] = !m[event.target.name]
    this.setState({ member: m })
  }

  saveMember() {
    if (this.state.new) {
      axios.post(BASE_URL+'vereinsmitglieder/', this.state.member, this.props.config).then(res => {
        this.setState({member: res.data, errors: {}, new: false })
        toast.success("es wurde gespeichert")
      }).catch(error => { 
        console.log(error.response.data);
        this.setState({ errors: error.response.data});
        toast.error("beim Speichern ist ein Fehler aufgetreten")
      })
    } else {
      axios.put(this.state.member.url, this.state.member, this.props.config).then(res => {
        toast.success("es wurde gespeichert")
        this.setState({ errors: {} });
      }).catch(error => { this.setState({ errors: error.response.data}) })
       // toast.error("beim Speichern ist ein Fehler aufgetreten")
    }
  }

  deleteMember() {
    axios.delete(this.state.member.url).then(res => {
      window.history.back()
    })
  }

  changeVortrag = e => {
    let mgl = this.state.member;
    if (e.target.value) {
      mgl.vortrag.push(e.target.name)
    } else {
      mgl.vortrag.splice( mgl.vortrag.indexOf(e.target.name), 1 );
    }
    this.setState({ member: mgl })
  }

  setUrl = (url, what) => {
    let mgl = this.state.member;
    mgl[what] = url;
    this.setState({ member: mgl }, this.saveMember());
  }

  changeStorndat = () => {
    let event = {target: {value: null, name: 'storndat' }}
    if (!this.state.member.storndat) {
      let d = new Date()
      event.target.value = d.toISOString().split('T')[0]
    }
    this.onChange(event);
    console.log("event")
    console.log(event)
  }

  addOffenePosten = () => {
    let m = this.state.member
    m.offeneposten_set.push("new");
    this.setState({ member: m })

  }

  reload = () => {
    let m =this.state.member
    axios.get(m.url).then(res => {
      m.offeneposten_set = res.data.offeneposten_set;
      this.setState({ member: m })
    })
  }





  render() {
    const mgl = this.state.member
    let aktives_mitglied = false
    if (!mgl.storndat) {
      aktives_mitglied = true
    }
    if (this.state.loading) {
      return <Loader />
    } else {
      return (<div className="row">
          <Container>
              <Button color="primary" onClick={this.saveMember}>Speichern</Button>
              <Button color="primary" onClick={this.deleteMember}>Löschen</Button>
              {Object.keys(this.state.errors).map( errs => (
                <Alert color="danger"><b>{errs+': '}</b>{this.state.errors[errs]}</Alert>
              ))}

              <InputField name="dsgvo" data={this.props.options.dsgvo} value={mgl.dsgvo} onChange={this.onToggle} />
              <InputField name="aktiv" data={{'type': 'boolean', 'label': 'Aktives Mitglied'}} value={aktives_mitglied} onChange={this.changeStorndat} />
              <BevCard key={1} title="Vortragsorte">
                { this.props.vortragLoaded && <Row>
                    {this.props.vortragsorte.map((v,i) => (
                      <InputField key={i} name={v.url} data={v} value={Boolean(mgl.vortrag.indexOf(v.url)+1)} onChange={this.changeVortrag}  />
                    ))}
                  </Row>
                }
              </BevCard>

              <BevCard key={11} title="login Credentials" open={false} show={this.props.user.is_superuser}>
                <Row>
                  <Col sm={4} style={{margin: '5px'}}>
                    <InputField name="is_active" data={this.props.options.is_active} value={mgl.is_active} onChange={this.onToggle}/>
                    <InputField name="is_staff" data={this.props.options.is_staff} value={mgl.is_staff} onChange={this.onToggle}/>
                    <InputField name="is_superuser" data={this.props.options.is_superuser} value={mgl.is_superuser} onChange={this.onToggle}/>
                  </Col>
                  <Col sm={7}>
                    <InputField name="username" data={this.props.options.username} value={mgl.username} onChange={this.onChange}/>
                    <div style={{marginTop: '12px', marginLeft: '10px', color: 'grey'}}>Last login: {mgl.last_login}</div>
                    <InputField name="last_login" data={this.props.options.last_login} value={mgl.last_login} onChange={this.onChange}/>
                    <InputField name="date_joined" data={this.props.options.date_joined} value={mgl.date_joined} onChange={this.onChange}/>
                  </Col>
                </Row>
              
              </BevCard>

              <BevCard key={2} title="persönliche Daten">
                <Row>
                  <Col sm={4} style={{margin: '5px'}}>
                    <h4>Mitgliedsnummer: {mgl.mitgliedsnummer}</h4>
                    <InputField name="gebdat" data={this.props.options.gebdat} value={mgl.gebdat} onChange={this.onChange}/>
                    <InputField name="beidat" data={this.props.options.beidat} value={mgl.beidat} onChange={this.onChange}/>
                    <InputField name="beigz" data={this.props.options.beigz} value={mgl.beigz} onChange={this.onChange}/>
                    <InputField name="diplomdat" data={this.props.options.diplomdat} value={mgl.diplomdat} onChange={this.onChange}/>
                    <InputField name="diplomort" data={this.props.options.diplomort} value={mgl.diplomort} onChange={this.onChange}/>
                    <InputField name="mitgliedsnummer" data={this.props.options.mitgliedsnummer} value={mgl.mitgliedsnummer} onChange={this.onChange}/>

                    <InputField name="storndat" data={this.props.options.storndat} value={mgl.storndat} onChange={this.onChange}/>

                    <InputField name="mitgliedsart" data={this.props.options.mitgliedsart} value={mgl.mitgliedsart} onChange={this.onChange}/>
                    <FormModal open={this.state.mitgliedModal} title="Edit Mitgliedsarten" 
                        handleClose={() => {this.setState({mitgliedModal: !this.state.mitgliedModal}, this.props.updateMemberOptions() )}}>
                      <Mitgliedsarten />
                    </FormModal>
                    <p onClick={() => {this.setState({mitgliedModal: !this.state.mitgliedModal})}}>Mitgliedsarten bearbeiten</p>

                    <InputField name="kostenart" data={this.props.options.kostenart} value={mgl.kostenart} onChange={this.onChange}/>
                    <FormModal open={this.state.kostenModal} title="Edit Kostenarten" handleClose={() => {this.setState({kostenModal: !this.state.kostenModal}, this.props.updateMemberOptions())}}>
                      <Kosten />
                    </FormModal>
                    <p onClick={() => {this.setState({kostenModal: !this.state.kostenModal})}}>Kostenarten bearbeiten</p>

                    <InputField name="versand" data={this.props.options.versand} value={mgl.versand} onChange={this.onChange}/>
                    <InputField name="sub" data={this.props.options.sub} value={mgl.sub} onChange={this.onChange}/>
                    <InputField name="heftanzahl" data={this.props.options.heftanzahl} value={mgl.heftanzahl} onChange={this.onChange}/>
                  </Col>
                  <Col sm={7} style={{margin: '5px'}}>
                    <InputField name="anrede" data={this.props.options.anrede} value={mgl.anrede} onChange={this.onChange}/>
                    <InputField name="titel" data={this.props.options.titel} value={mgl.titel} onChange={this.onChange}/>
                    <InputField name="first_name" data={this.props.options.first_name} value={mgl.first_name} onChange={this.onChange}/>
                    <InputField name="last_name" data={this.props.options.last_name} value={mgl.last_name} onChange={this.onChange}/>
                    <InputField name="namenszusatz" data={this.props.options.namenszusatz} value={mgl.namenszusatz} onChange={this.onChange}/>
                    <InputField name="email" data={this.props.options.email} value={mgl.email} onChange={this.onChange}/>
                    <InputField name="berufsgruppe" data={this.props.options.berufsgruppe} value={mgl.berufsgruppe} onChange={this.onChange}/>
                    <FormModal open={this.state.berufsModal} title="Edit Berufsgruppen" handleClose={() => {this.setState({berufsModal: !this.state.berufsModal})}}>
                      <Berufsgruppen />
                    </FormModal>
                    <p onClick={() => {this.setState({berufsModal: !this.state.berufsModal}, this.props.updateMemberOptions() )}}>Berufsgruppen bearbeiten</p>

                    
                    <InputField name="tel" data={this.props.options.tel} value={mgl.tel} onChange={this.onChange}/>
                    <InputField name="fax" data={this.props.options.fax} value={mgl.fax} onChange={this.onChange}/>
                    <InputField name="anmerkung" data={this.props.options.anmerkung} value={mgl.anmerkung} onChange={this.onChange}/>

                  </Col>
                </Row>
              </BevCard>

              { this.props.countriesLoaded && <BevCard key={3} title="Adressen" >
                  {/* <FormModal open={this.state.countriesModal} title="Edit Countries" handleClose={() => {this.setState({countriesModal: !this.state.countriesModal})}}>
                      <Countries />
                    </FormModal>
                    <p onClick={() => {this.setState({countriesModal: !this.state.countriesModal}, this.props.updateMemberOptions() )}}>Countries bearbeiten</p> */}
                    <Row>

                      <Col sm={4}>
                        <Adresse dataurl={mgl.wohnadresse} title={'Wohnadresse'} setUrl={(url) => this.setUrl(url, 'wohnadresse')} />
                      </Col>
                      <Col sm={4}>
                        <Adresse dataurl={mgl.lieferadresse} title={'Lieferadresse'} setUrl={(url) => this.setUrl(url, 'lieferadresse')} />
                      </Col>
                      <Col sm={4}>
                        <Adresse dataurl={mgl.rechnungsadresse} title={'Rechnungsadresse'} setUrl={(url) => this.setUrl(url, 'rechnungsadresse')} />
                      </Col>
                    </Row>
                  </BevCard>
              }

              <BevCard key={4} title="Mitgliedsbeiträge">
                <List>
                <Button color="primary" onClick={this.addOffenePosten}>neuen Mitgliedsbeitrag anlegen</Button>
                <Button color="primary" onClick={this.createInvoice} target='_blank'>Zahlschein anzeigen</Button>
                <Button color="primary" onClick={() => (this.sendMail(BASE_URL+'rechnung/'+mgl.id+'/'))}>Zahlschein ausschicken</Button>

                  {mgl.offeneposten_set.map((op, i) => (
                    <OffenePosten key={i} url={op} mitglied={mgl.url} reload={this.reload}/>
                  ))}
                </List>
              </BevCard>

          </Container>}
        </div>)
    }
  }
}

Mitglieder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Mitglieder));