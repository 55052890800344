import React, { Component } from 'react';
import axios from 'axios';
import * as actions from '../store/actions';
import { BASE_URL } from '../common/constants';
import { Container, Row, Col } from 'reactstrap';
import DashPanel from '../components/DashPanel';
import { connect } from 'react-redux';
import Loader from '../common/loader';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  }
}


const mapStateToProps = (state) => {
  return {
    title: state.base.title,
  }
}

class Finanzen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dash_nums: false,
    }
  }

  componentDidMount() {
    axios.get(BASE_URL + 'dashboard').then(res => {
      this.setState({
        loading: false,
        dash_nums: res.data
      });
    })
    this.props.setTitle("Finanzen");

  }


  render() {
    return <div>
      { this.state.loading ? <Loader /> :
        <Container>
          <Row style={{marginBottom: '10px'}}>
            <Col sm={4}>
              <DashPanel value={this.state.dash_nums.offenerbetrag + "€"} text="an Mitgliedsbeiträgen offen" link='/finanzen/mitgliedsbeitraege' />
            </Col>
            <Col sm={4}>
              <DashPanel value={this.state.dash_nums.kosten} text="Kostenarten" link='/finanzen/kosten' />
            </Col>
            <Col sm={4}>
              <DashPanel value={"Jahresbeitrag"} text="anlegen" link='/finanzen/neuer_jahresbeitrag' />
            </Col>
          </Row>
          <Row style={{marginBottom: '10px'}}>
            <Col sm={4}>
              <DashPanel value={"Standardtexte"} text="eingeben" link='/finanzen/standardtexte' />
            </Col>
    
          </Row>
        </Container>
      }
      </div>
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Finanzen)
