import React, { Component } from 'react';
import axios from 'axios';
import * as actions from '../store/actions';
import { BASE_URL } from '../common/constants';
import { Container, Row, Col } from 'reactstrap';
import DashPanel from '../components/DashPanel';
import { connect } from 'react-redux';
import Loader from '../common/loader';



const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
    hasPermission: (groupname) => actions.auth.hasPermission(groupname),
  }
}

const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    user: state.auth.user,
    config: state.auth.config,
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dash_nums: false,
    }
  }

  componentDidMount() {
    axios.get(BASE_URL + 'dashboard/', this.props.config).then(res => {
      this.setState({
        loading: false,
        dash_nums: res.data
      });
      console.log(res);
    })
    this.props.setTitle("Dashboard");

  }


  render() {
    return <div>
      { this.state.loading ? <Loader /> :
        <Container>
          <Row style={{marginBottom: '10px'}}>
            <Col sm={4}>
              <DashPanel value={this.state.dash_nums.mitgliedercount.aktiv + " ("+this.state.dash_nums.mitgliedercount.inaktiv+")"} text="Mitglieder" link='/mitglieder/'/>
            </Col>
            <Col sm={4}>
              <DashPanel value={this.state.dash_nums.institutionencount.aktiv + " ("+this.state.dash_nums.institutionencount.inaktiv+")"} text="Institutionen" link='/institutionen'/>
            </Col>
            <Col sm={4}>
              <DashPanel value={this.state.dash_nums.jubilare.length} text="Jubilare" link='/jubilare' />
            </Col>
          </Row>
          { this.props.hasPermission("Abonnenten") &&
            <Row style={{marginBottom: '10px'}}>
              <Col sm={4}>
                <DashPanel value={this.state.dash_nums.abonnentcount.aktiv + " (" + this.state.dash_nums.abonnentcount.inaktiv + ")"} text="Abonnenten" link='/abonnenten' />
              </Col>
              
              <Col sm={4}>
                <DashPanel value={"Etiketten"} text="fürs VGI" link='/etiketten' />
              </Col>
            </Row>
          }
          
          { this.props.hasPermission("Finannz")  && 
            <Row style={{marginBottom: '10px'}}>
              <Col sm={4}>
                <DashPanel value={"Finanz"} text="Übersicht" link='/finanzen' />
              </Col>
              <Col sm={4}>
                <DashPanel value={this.state.dash_nums.mitgliedsarten} text="Mitgliedsarten" link='/mitgliedsarten' />
              </Col> 
              <Col sm={4}>
                <DashPanel value={this.state.dash_nums.berufecount} text="Berufe" link='/berufe' />
              </Col>
            </Row>
          }



          <Row style={{marginBottom: '10px'}}>
            <Col sm={4}>
              <DashPanel value={this.state.dash_nums.laendercount} text="Laender" link='/laender' />
            </Col>
          </Row>
        </Container>
      }
      </div>
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
