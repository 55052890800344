import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import * as actions from '../store/actions';
import { BASE_URL } from '../common/constants';
import Loader from '../common/loader';
import FormModal from '../components/FormModal';
import Form from '../components/Form';


const styles = theme => ({
  root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  grid: {
      flexGrow: 1,
    },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateOffeneAboPostenOptions: () => dispatch(actions.base.updateOffeneAboPostenOptionsSaga()),

  }
}


const mapStateToProps = (state) => {
  return {
    options: state.base.offeneAboPostenOptions,
    config: state.auth.config,

  }
}

class OffeneAboPosten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      op: {},
      modal: false,
    }
    this.saveoffeneAboPosten = this.saveoffeneAboPosten.bind(this);
    this.deleteoffeneAboPosten = this.deleteoffeneAboPosten.bind(this);
  }



  componentDidMount() {
    if (this.props.url === "new") {
      this.setState({ 
        op: {
          abo: this.props.aboheft, 
          url: 'new',
          aboheft: this.props.aboheft.url,
          offen: this.props.aboheft.country == "https://members.ovg.at/api/countries/43/" ? 60 : 75,
        }, 
        loading: false,
        modal: true,
      })
    } else {
      axios.get(this.props.url, this.props.config).then(res => {
        this.setState({ op: res.data, loading: true })
      }).then(() => {this.setState({ loading: false}) }).catch(err => {
        console.log("so nicht mein freund");
      });
    }
  }

  onChange = event => {
    let m = this.state.op
    m[event.target.name] = event.target.value;
    this.setState({ op: m });
  }

  onToggle = event => {
    let m = this.state.op
    m[event.target.name] = !m[event.target.name]
    this.setState({ op: m })
  }

  saveoffeneAboPosten() {
    axios.put(this.state.op.url, this.state.op, this.props.config).then(res => {
      console.log("es wurde gespeichert")
    });
  }

  deleteoffeneAboPosten() {
    axios.delete(this.state.op.url, this.props.config).then(res => {
      this.props.history.push('/')
    })
  }
  
  closeModal = () => {
    this.setState({ modal: false, })
  }

  formChange = event => {
    console.log(event.target.name);
    console.log(event.target.value);
    let op = this.state.op
    op[event.target.name] = event.target.value;
    if (event.target.name === 'bezahlt') {
      if (event.target.value === true) {
        op.zahlung = op.offen*(1-this.props.abo.prozent)
        op.bezahltam = new Date().toISOString()
      } else {
        op.zahlung = 0
        op.bezahltam = null
      }
    }
    if (event.target.name === 'zahlung') {
      if (event.target.value > 0) { 
        op.bezahlt = true
        op.bezahltam = new Date().toISOString()
      } else { 
        op.bezahlt = false
        op.bezahltam = null
      }
    }

    this.setState({op: op})
    console.log(this.state.op)
  }

  showModal = () => {
    this.setState({ modal: true, })
  }


  render() {
    let op = this.state.op
    let classes = this.props
    return <div>
      { this.state.loading ? <Loader /> : <div>
        <div onClick={() => {console.log("click");this.setState({modal: true}) } } >
          <ListItem key={op.url} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar style={op.bezahlt ? {backgroundColor: 'green'} : {backgroundColor: 'red'}}>€</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={op.description}
            secondary={
              <React.Fragment>
                <Typography component="span" className={classes.inline} color="textPrimary">
                  Bezahlt am {op.bezahltam}
                </Typography>
                offen: € {op.offen}
              </React.Fragment>
            }
          />
        </ListItem>
      </div>
      <FormModal open={this.state.modal} title="Edit offeneAboPosten" handleClose={this.closeModal}>
          <Form values={op}
            options={this.props.options}
            handleCancel={this.closeModal}
            onChange={this.formChange} 
            postUrl={this.props.url === "new" ? BASE_URL+'offeneaboposten/' : undefined}
            postSave={(data) => this.setState({ op: data }) }
            handleDelete={this.props.reload}
            />          
      </FormModal>
    </div>
    }
    </div>
  }
}

OffeneAboPosten.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string
};

OffeneAboPosten.defaultProps = {
  url: "new",
  mitgliedsnummer: '',
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OffeneAboPosten));
