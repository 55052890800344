export const UPDATE_MEMBER_LIST = 'UPDATE_MEMBER_LIST';
export const UPDATE_MEMBER_OPTIONS = 'UPDATE_MEMBER_OPTIONS';
export const UPDATE_FOR_MEMBER = 'UPDATE_FOR_MEMBER';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';
export const UPDATE_COUNTRIES_OPTIONS = 'UPDATE_COUNTRIES_OPTIONS';
export const SET_COUNTRIES_LOADED = 'SET_COUNTRIES_LOADED';
export const SET_VORTRAG_LOADED = 'SET_VORTRAG_LOADED';
export const SET_VORTRAGSORTE = 'SET_VORTRAGSORTE';
export const UPDATE_FOR_VORTRAG = 'UPDATE_FOR_VORTRAG';
export const UPDATE_OFFENE_POSTEN_OPTIONS = 'UPDATE_OFFENE_POSTEN_OPTIONS';
export const SET_OFFENE_POSTEN_OPTIONS = 'SET_OFFENE_POSTEN_OPTIONS';
export const UPDATE_FOR_INSTITUTIONS = 'UPDATE_FOR_INSTITUTIONS';
export const UPDATE_FOR_ABONNENT = 'UPDATE_FOR_ABONNENT';
export const UPDATE_FOR_ABOHEFT = 'UPDATE_FOR_ABOHEFT';
export const UPDATE_FOR_OFFENE_ABO_POSTEN = 'UPDATE_FOR_OFFENE_ABO_POSTEN';
export const UPDATE_FOR_KOSTEN = 'UPDATE_FOR_KOSTEN';
export const UPDATE_OFFENE_ABO_POSTEN_OPTIONS = 'UPDATE_OFFENE_ABO_POSTEN_OPTIONS';

export const SET_ZAHLSCHEIN_TEXT = 'SET_ZAHLSCHEIN_TEXT';
export const SET_EMAIL_TEXT = 'SET_EMAIL_TEXT';


export const UPDATE_ADRESSE_OPTIONS = 'UPDATE_ADRESSE_OPTIONS';
export const UPDATE_INSTITUTION_OPTIONS = 'UPDATE_INSTITUTION_OPTIONS';
export const UPDATE_ABONNENT_OPTIONS = 'UPDATE_ABONNENT_OPTIONS';
export const UPDATE_ABOHEFT_OPTIONS = 'UPDATE_ABOHEFT_OPTIONS';
export const UPDATE_KOSTEN_OPTIONS = 'UPDATE_KOSTEN_OPTIONS';
export const UPDATE_MITART_OPTIONS = 'UPDATE_MITART_OPTIONS';
export const UPDATE_BERUFE_OPTIONS = 'UPDATE_BERUFE_OPTIONS';
export const UPDATE_USER_DETAILS_OPTIONS = 'UPDATE_USER_DETAILS_OPTIONS';

export const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const AUTH_LOGOUT_SUCCESSFUL = 'AUTH_LOGOUT_SUCCESSFUL';
export const AUTH_LOGIN_SUCCESSFUL = 'AUTH_LOGIN_SUCCESSFUL';
export const AUTH_LOAD_USER = 'AUTH_LOAD_USER';
export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const AUTH_USER_LOADED = 'AUTH_USER_LOADED';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_AUTHENTICATION_ERROR = 'AUTH_AUTHENTICATION_ERROR';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';


export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_COUNTRIES_OPTIONS = 'SET_COUNTRIES_OPTIONS';

export const UPDATE_BERUFE = 'UPDATE_BERUFE';
export const SET_BERUFE = 'SET_BERUFE';

export const BASE_SET_PROGRESS = 'BASE_SET_PROGRESS';
export const SET_MEMBER = 'SET_MEMBER';
export const SET_TITLE = 'SET_TITLE';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
