import React from 'react';
import { connect } from 'react-redux';
import * as actions from './store/actions';
import Mitglied from './hoc/Mitglied';
import Jubilare from './hoc/Jubilare';
import Abonnent from './hoc/Abonnent';
import Aboheft from './hoc/AboHeft';
import Institution from './hoc/Institution';
import Countries from './hoc/Countries';
import LoginPage from './hoc/LoginPage';
import Dashboard from './hoc/Dashboard';
import Mitgliedsbeitraege from './hoc/Mitgliedsbeitraege';
import Mitgliedsarten from './hoc/Mitgliedsarten';
import Etiketten from './hoc/Etiketten';
import Headerline from './components/Headerline';
import Finanzen from './hoc/Finanzen';
import Berufe from './hoc/Berufsgruppen';
import Kosten from './hoc/Kosten';
import JahresBeitragGesamt from './hoc/JahresBeitragGesamt';
import Laender from './hoc/Countries';
import {Route, Switch, BrowserRouter, Redirect, withRouter} from 'react-router-dom';
import UserMenu from './components/UserMenu';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mitglieder from './hoc/Mitglieder';
import Abonnenten from './hoc/Abonnenten';
import Institutionen from './hoc/Institutionen';
import Standardtexte from './hoc/Standardtexte';

import { Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
} from 'reactstrap';
// import TableView from './hoc/Institutionenalt';


// const Institutionen1 =  props => {
//   return <TableView titel="Institutionen" url="institutionen" memberurl = '/institution' location={props.location} fields={["mitgliedsnummer", "institution_name", "email"]} aktivfield="storndat" /> 
// }


// const Abonnenten1 =  props => {
//   return <TableView titel="Abonnenten" url="abonnent" memberurl ='/abonnent' location={props.location} fields={["kundennummer", "name", "prozent", "aboheft_set"]} aktivfield="inaktiv" /> 
// }



const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (title) => dispatch(actions.base.setTitle(title)),
    loadUser: () => dispatch(actions.auth.loadUser()),
    hasPermission: (groupname) => actions.auth.hasPermission(groupname),
    logout: () => dispatch(actions.auth.logout()),
  }
}

const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    auth: state.auth,
    isLoading: state.auth.isLoading,
  }
}


class StartSeite extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  PrivateRoute = ({component: ChildComponent, ...rest}) => {
    return <Route {...rest} render={props => {
      if (this.props.auth.isLoading) {
        return <em>Loading...</em>;
      } else if (!this.props.auth.isAuthenticated) {
        return <Redirect to="/login" />;
      } else {
        return <ChildComponent {...props} />
      }
    }} />
  }

  
  componentWillMount() {
    // this.props.loadUser();
  }

  componentDidMount() {
    this.props.loadUser();
  }


  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    let {PrivateRoute} = this

    return (
      <div key="ans">
        <Navbar color="light" light expand="md">
          <NavbarBrand><a href="/">OVG - Unsere Schäfchen</a></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
            
              <span>{this.props.auth.isAuthenticated ? <UserMenu username={this.props.auth.user.username} /> : 'anonymous'}</span>
             
            </Nav>
          </Collapse>
        </Navbar>
        <div style={{ textAlign: 'right' }}>
      </div>

      <Headerline />

      <div style={{ marginTop: '20px' }} key="zwa">
        <main>
          { this.state.isAuthenticated ? <h3>OVG DB wird geladen...</h3> : <BrowserRouter>
              <Switch>
                <Route exact path="/login/" component={ LoginPage } />
                <PrivateRoute exact path="/" component={ Dashboard } />
                <PrivateRoute exact path="/jubilare/" component={ Jubilare } />
                <PrivateRoute exact path="/countries/" component={ Countries } />
                <PrivateRoute exact path="/mitglieder/" component={ Mitglieder } />
                <PrivateRoute exact path="/mitglied/:id/" component={ Mitglied } />
                <PrivateRoute exact path="/institutionen/" component={ Institutionen } />
                <PrivateRoute exact path="/institution/:id/" component={ Institution } />
                <PrivateRoute exact path="/abonnenten/" component={ Abonnenten } />
                <PrivateRoute exact path="/abonnent/:id/" component={ Abonnent } />
                <PrivateRoute exact path="/aboheft/:id/" component={ Aboheft } />
                <PrivateRoute exact path="/mitgliedsarten/" component={ Mitgliedsarten } />
                <PrivateRoute exact path="/berufe/" component={ Berufe } />
                <PrivateRoute exact path="/laender/" component={ Laender } />
                <PrivateRoute exact path="/finanzen/" component={ Finanzen } />
                <PrivateRoute exact path="/finanzen/standardtexte" component={ Standardtexte } />
                <PrivateRoute exact path="/etiketten/" component={ Etiketten } />
                <PrivateRoute exact path="/finanzen/mitgliedsbeitraege/" component={ Mitgliedsbeitraege } />
                <PrivateRoute exact path="/finanzen/kosten/" component={ Kosten } />
                <PrivateRoute exact path="/finanzen/neuer_jahresbeitrag/" component={ JahresBeitragGesamt } />
              </Switch>
            </BrowserRouter>
          }
          </main>
          <ToastContainer hideProgressBar={true} />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartSeite))
