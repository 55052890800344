import { takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';

import {
    loadUser,
    login,
    logout,
} from './auth';

import {
    updateMemberOptions,
    updateCountriesOptions,
    updateVortragOptions,
    updateOffenePostenOptions,
    updateInstitutionOptions,
    updateAbonnentOptions,
    updateAboHeftOptions,
    updateKostenOptions,
    updateOffeneAboPostenOptions,
} from './base';


export const getToken = state => state.auth.userToken;

export function* watchAuth() {
//   yield takeEvery(actionTypes.AUTH_LOGOUT, authLogoutSaga);
//   yield takeEvery(actionTypes.AUTH_TOKEN_OBTAIN, authTokenObtainSaga);
//   yield takeEvery(actionTypes.AUTH_TOKEN_REFRESH, authTokenRefreshSaga);
//   yield takeEvery(actionTypes.AUTH_TOKEN_VERIFY, authTokenVerifySaga);
//   yield takeEvery(actionTypes.AUTH_TOKEN_CHECK_EXPIRATION, authTokenCheckExpirationSaga);
//   yield takeEvery(actionTypes.AUTH_TIMEOUT, authCheckTimeout);

yield takeEvery(actionTypes.AUTH_LOAD_USER, loadUser);
yield takeEvery(actionTypes.AUTH_LOGIN_USER, login);
yield takeEvery(actionTypes.AUTH_LOGOUT_USER, logout);

yield takeEvery(actionTypes.UPDATE_FOR_MEMBER, updateMemberOptions);
yield takeEvery(actionTypes.UPDATE_FOR_VORTRAG, updateVortragOptions);
yield takeEvery(actionTypes.UPDATE_FOR_INSTITUTIONS, updateInstitutionOptions);
yield takeEvery(actionTypes.UPDATE_COUNTRIES, updateCountriesOptions);
yield takeEvery(actionTypes.UPDATE_OFFENE_POSTEN_OPTIONS, updateOffenePostenOptions);
yield takeEvery(actionTypes.UPDATE_FOR_ABONNENT, updateAbonnentOptions);
yield takeEvery(actionTypes.UPDATE_FOR_ABOHEFT, updateAboHeftOptions);
yield takeEvery(actionTypes.UPDATE_FOR_KOSTEN, updateKostenOptions);
yield takeEvery(actionTypes.UPDATE_FOR_OFFENE_ABO_POSTEN, updateOffeneAboPostenOptions);

}
