import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import * as actions from "../store/actions";
import axios from "axios";
import { BASE_URL } from "../common/constants";
import Loader from "../common/loader";
import FormModal from "../components/FormModal";
import Form from "../components/Form";
import BevCard from "../components/BevCard";
import { Container, Row, Col } from "reactstrap";
import InputField from "../components/InputField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import OffeneAboPosten from "./offeneAboPosten";
import Divider from "@material-ui/core/Divider";
import { toast } from "react-toastify";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  grid: {
    flexGrow: 1,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAboHeftOptions: () =>
      dispatch(actions.base.updateAboHeftOptionsSaga()),
    updateOffeneAboPostenOptions: () =>
      dispatch(actions.base.updateOffeneAboPostenOptionsSaga()),
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  };
};

const mapStateToProps = (state) => {
  return {
    options: state.base.aboheftOptions,
    config: state.auth.config,
  };
};

class AboHeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      countriesModal: false,
      berufsModal: false,
      abo: {},
      modal: false,
      new: this.props.new,
    };
  }

  componentDidMount() {
    this.props.updateAboHeftOptions();
    this.props.updateOffeneAboPostenOptions();
    console.log(this.props.match.params);

    if (this.props.match.params.id === "new") {
      this.setState({ abo: {} });
    } else {
      axios
        .get(
          BASE_URL + "/aboheft/" + this.props.match.params.id + "/",
          this.props.config
        )
        .then((res) => {
          this.props.setTitle("Aboheft ");
          this.setState({ abo: res.data, loading: false });
        })
        .catch((err) => {
          console.log(err);
          console.log("so nicht mein freund");
        });
    }
  }

  addOffeneAboPosten = () => {
    let m = this.state.abo;
    m.offeneaboposten_set.push("new");
    this.setState({ abo: m });
  };

  onChange = (event) => {
    let m = this.state.abo;
    m[event.target.name] = event.target.value;
    this.setState({ abo: m });
  };

  formChange = (event) => {
    console.log(event.target.name);
    console.log(event.target.value);
    let abo = this.state.abo;
    abo[event.target.name] = event.target.value;
    this.setState(abo);
    console.log(this.state.abo);
  };

  showModal = () => {
    this.setState({ modal: true });
  };

  changeAboEnde = () => {
    let event = { target: { value: null, name: "aboende" } };
    if (!this.state.abo.aboende) {
      let d = new Date();
      event.target.value = d.toISOString().split("T")[0];
    }
    this.onChange(event);
    console.log("event");
    console.log(event);
  };

  saveAboHeft = () => {
    if (this.state.new) {
      axios
        .post(BASE_URL + "aboheft/", this.state.abo, this.props.config)
        .then((res) => {
          this.setState({ abo: res.data, errors: {}, new: false });
          toast.success("es wurde gespeichert");
        })
        .catch((error) => {
          console.log(error.response.data);
          this.setState({ errors: error.response.data });
          toast.error("beim Speichern ist ein Fehler aufgetreten");
        });
    } else {
      axios
        .put(this.state.abo.url, this.state.abo, this.props.config)
        .then((res) => {
          toast.success("es wurde gespeichert");
          this.setState({ errors: {} });
        })
        .catch((error) => {
          this.setState({ errors: error.response.data });
        });
      // toast.error("beim Speichern ist ein Fehler aufgetreten")
    }
  };

  deleteAboHeft = () => {
    axios.delete(this.state.abo.url, this.props.config).then((res) => {
      window.history.back();
    });
  };

  createInvoice = () => {
    let url = this.state.abo.url + "/create_invoice/";
    axios
      .get(url, this.props.config)
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        toast.warn(err.response.data);
      });
  };

  render() {
    let abo = this.state.abo;
    let aktives_heft = false;
    if (!abo.aboende) {
      aktives_heft = true;
    }
    let classes = this.props;
    console.log(this.props.options);
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <Container>
            <h4>
              <a href={"/abonnent/" + abo.abonnent_id}>
                &lt; {abo.abonnent_name}{" "}
              </a>
            </h4>
            <Button color="primary" onClick={this.saveAboHeft}>
              Speichern
            </Button>
            <Button color="primary" onClick={this.deleteAboHeft}>
              Löschen
            </Button>
            <InputField
              name="aktiv"
              data={{ type: "boolean", label: "Aktives AboHeft" }}
              value={aktives_heft}
              onChange={this.changeAboEnde}
            />

            <Divider />

            <BevCard title="Abodaten" open={true}>
              <Row>
                <Col sm={2} style={{ margin: "5px" }}>
                  <InputField
                    name="abonummer"
                    data={this.props.options.abonummer}
                    value={abo.abonummer}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="kundennummer"
                    data={this.props.options.kundennummer}
                    value={abo.kundennummer}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="heftanzahl"
                    data={this.props.options.heftanzahl}
                    value={abo.heftanzahl}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="beigz"
                    data={this.props.options.beigz}
                    value={abo.beigz}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="aboanfang"
                    data={this.props.options.aboanfang}
                    value={abo.aboanfang}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="aboende"
                    data={this.props.options.aboende}
                    value={abo.aboende}
                    onChange={this.onChange}
                  />
                </Col>
                <Col sm={6} style={{ margin: "5px" }}>
                  <InputField
                    name="anrede"
                    data={this.props.options.anrede}
                    value={abo.anrede}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="titel"
                    data={this.props.options.titel}
                    value={abo.titel}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="vorname"
                    data={this.props.options.vorname}
                    value={abo.vorname}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="nachname"
                    data={this.props.options.nachname}
                    value={abo.nachname}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="surname2"
                    data={this.props.options.surname2}
                    value={abo.surname2}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="surname3"
                    data={this.props.options.surname3}
                    value={abo.surname3}
                    onChange={this.onChange}
                  />

                  <InputField
                    name="tel"
                    data={this.props.options.tel}
                    value={abo.tel}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="fax"
                    data={this.props.options.fax}
                    value={abo.fax}
                    onChange={this.onChange}
                  />
                </Col>
                <Col sm={3} style={{ margin: "5px" }}>
                  <InputField
                    name="aboart"
                    data={this.props.options.aboart}
                    value={abo.aboart}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="anmerkung"
                    data={this.props.options.anmerkung}
                    value={abo.anmerkung}
                    onChange={this.onChange}
                  />

                  <h4 style={{ marginTop: "20px" }}> Adresse</h4>
                  <InputField
                    name="strasse"
                    data={this.props.options.strasse}
                    value={abo.strasse}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="plz"
                    data={this.props.options.plz}
                    value={abo.plz}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="ort"
                    data={this.props.options.ort}
                    value={abo.ort}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="pobox"
                    data={this.props.options.pobox}
                    value={abo.pobox}
                    onChange={this.onChange}
                  />
                  <InputField
                    name="country"
                    data={this.props.options.country}
                    value={abo.country}
                    onChange={this.onChange}
                  />
                </Col>
              </Row>
            </BevCard>
            <BevCard key={4} title="AboPreis">
              <List>
                <Button color="primary" onClick={this.addOffeneAboPosten}>
                  neuen AboPreis anlegen
                </Button>
                <Button
                  color="primary"
                  onClick={this.createInvoice}
                  target="_blank"
                >
                  Rechnung anzeigen
                </Button>

                {abo.offeneaboposten_set.map((op, i) => (
                  <OffeneAboPosten
                    key={i}
                    url={op.url}
                    aboheft={abo}
                    reload={this.reload}
                  />
                ))}
              </List>
            </BevCard>
          </Container>
        </div>
      );
    }
  }
}

AboHeft.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string,
  new: PropTypes.boolean,
};

AboHeft.defaultProps = {
  url: "new",
  kundennummer: "",
  new: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AboHeft));
