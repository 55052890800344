import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import { BASE_URL } from '../common/constants';
import { Container } from 'reactstrap';
import SearchBar from '../components/SearchBar';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { toast } from 'react-toastify';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  }
}

const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    config: state.auth.config,
  }
}

class JahresBeitragGesamt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jahr: '',
      warten: false,
      geladen: false,
      tabValue: 0,
    }
  }

 
  componentDidMount() {
    this.props.setTitle("Jahresbeiträge ")
  }

  anlegen  = (what, hauspost) => {
    console.log(what)
    this.setState({ warten: true })
    axios.get(BASE_URL+what+'/jahresbeitrag_anlegen/?jahr='+ this.state.jahr +'&hauspost='+ hauspost, this.props.config).then(res => {
      toast.success(res.data)
      this.setState({geladen: res.data, warten: false})
    }).catch(err => {
      this.setState({ warten: false })
    })
  }  

  erlagscheine  = (what, hauspost) => {
    this.setState({ warten: true })
    axios.get(BASE_URL+what+'/erlagscheine_anlegen/?hauspost='+hauspost, this.props.config).then(res => {
      toast.success("jawoi")
      const file = new Blob([res.data], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL)
    }).catch(err => {
      this.setState({ warten: false })
    })
  } 
  
  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };


  render() {
    const { tabValue } = this.state;

    return <Container>

      <div className={''}>
        <AppBar position="static">
          <Tabs value={tabValue} onChange={this.handleChange}>
            <Tab label="Mitglieder" />
            <Tab label="Mitglieder Hauspost" />
            <Tab label="Institutionen" />
            <Tab label="Abohefte" />
          </Tabs>
        </AppBar>
        {tabValue === 0 && <TabContainer>
          
          <h1 style={{color: 'red'}}> Achtung, jetzt werden die Beiträge nur für Mitglieder angelegt!!!</h1>
          <p>
            <SearchBar value={this.state.jahr} 
              help_text={"Durch Eingabe einer Jahreszahl können die Mitgliedsbeiträge für dieses Jahr berechnet werden"}
              label={"Beitrag für [YYYY]"} changeValue={(e) => {this.setState({ jahr: e.target.value })}} />
          </p>
          <p>
            <Button color="primary" onClick={() => this.anlegen('vereinsmitglieder', false) }>Neue Jahresbeitrag anlegen</Button>
          </p>
          { this.state.warten && <div><h3>Berechnung wird durchgeführt</h3><LinearProgress /></div> }
          { this.state.geladen && <h2>{this.state.geladen.success}</h2> }


          <p>
            <Button color="primary" onClick={() => this.erlagscheine('vereinsmitglieder', false) }>Erlagscheine anzeigen</Button>
          </p>
          
        </TabContainer>}
        
        {tabValue === 1 && <TabContainer>
          
          <h1 style={{color: 'red'}}> Achtung, jetzt werden die Beiträge nur für Mitglieder Hauspost angelegt!!!</h1>
          <p>
            <SearchBar value={this.state.jahr} 
              help_text={"Durch Eingabe einer Jahreszahl können die Mitgliedsbeiträge für dieses Jahr berechnet werden"}
              label={"Beitrag für [YYYY]"} changeValue={(e) => {this.setState({ jahr: e.target.value })}} />
          </p>
          <p>
            <Button color="primary" onClick={() => this.anlegen('vereinsmitglieder', true) }>Neue Jahresbeitrag anlegen</Button>
          </p>
          { this.state.warten && <div><h3>Berechnung wird durchgeführt</h3><LinearProgress /></div> }
          { this.state.geladen && <h2>{this.state.geladen.success}</h2> }


          <p>
            <Button color="primary" onClick={() => this.erlagscheine('vereinsmitglieder', true) }>Erlagscheine anzeigen</Button>
          </p>
          
        </TabContainer>}


        {tabValue === 2 && <TabContainer>
          <h1 style={{color: 'red'}}> Achtung, jetzt werden die Beiträge nur für Institutionen angelegt!!!</h1>

          <p>
            <SearchBar value={this.state.jahr} 
              help_text={"Durch Eingabe einer Jahreszahl können die Mitgliedsbeiträge für dieses Jahr berechnet werden"}
              label={"Beitrag für [YYYY]"} changeValue={(e) => {this.setState({ jahr: e.target.value })}} />
          </p>
          <p>
            <Button color="primary" onClick={ () => this.anlegen('institutionen', false) }>Neue Jahresbeitrag anlegen</Button>
          </p>
          { this.state.warten && <div><h3>Berechnung wird durchgeführt</h3><LinearProgress /></div> }
          { this.state.geladen && <h2>{this.state.geladen.success}</h2> }


          <p>
            <Button color="primary" onClick={() => this.erlagscheine('institutionen', false)}>Erlagscheine anzeigen</Button>
          </p>          
        </TabContainer>}   
        
        {tabValue === 3 && <TabContainer>
          <h1 style={{color: 'red'}}> Achtung, jetzt werden die Beiträge für die Abonnenten angelegt!!!</h1>

          <p>
            <SearchBar value={this.state.jahr} 
              help_text={"Durch Eingabe einer Jahreszahl können die Aboheftpreise für dieses Jahr berechnet werden"}
              label={"Beitrag für [YYYY]"} changeValue={(e) => {this.setState({ jahr: e.target.value })}} />
          </p>
          <p>
            <Button color="primary" onClick={ () => this.anlegen('aboheft', false) }>Neue Jahresbeitrag anlegen</Button>
          </p>
          { this.state.warten && <div><h3>Berechnung wird durchgeführt</h3><LinearProgress /></div> }
          { this.state.geladen && <h2>{this.state.geladen.success}</h2> }

          <p>
            <Button color="primary" onClick={() => this.erlagscheine('aboheft', false)}>Erlagscheine anzeigen</Button>
          </p>  
               
        </TabContainer>}

      </div>


      
    </Container>
  }
}

JahresBeitragGesamt.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(JahresBeitragGesamt)
