import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import * as actions from '../store/actions';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../common/constants';
import Button from '@material-ui/core/Button';
import { Container, Row,  } from 'reactstrap';
import FormModal from '../components/FormModal';
import Form from '../components/Form';
import CheckIcon from '@material-ui/icons/Check';


const styles = theme => ({
  root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
    updateLaenderOptions: () => dispatch(actions.base.updateCountriesSaga()),
  }
}


const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    laenderOptions: state.base.laenderOptions,
    config: state.auth.config,
  }
}


class Laender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      laender: false,
      land: false,
      modal: false,
    }
  }

  componentDidMount() {
    this.props.updateLaenderOptions();
    axios.get(BASE_URL + 'countries', this.props.config).then(res => {
      this.setState({ loading: false, laender: res.data, });
    });
    this.props.setTitle("Länder")
  }

  formChange = event => {
    let m = this.state.land
    m[event.target.name] = event.target.value;
    this.setState({ land: m });
  }

  closeModal = () => {
    this.setState({modal:false});
  }

  reload = () => {
    axios.get(BASE_URL + 'countries').then(res => {
      this.setState({ loading: false, laender: res.data, });
    });
  }

  render() {
    return <Container><Row>
      { this.state.loading ? <h2>Daten werden geladen...</h2> :   <Row>
            <Button color="primary"
                onClick={ () => { this.setState({modal:true, land: {url: 'new'}}) } }
              >Neues Land anlegen</Button>
            <Table>
              <thead>
                <tr>
                  <th>id</th>
                  <th>iso</th>
                  <th>land</th>
                  <th>EU</th>
                  <th>mitglieder</th>
                  <th>institutionen</th>
                </tr>
              </thead>
              <tbody>
                {this.state.laender.results.map((v,i) => (<tr key={v.id}>
                    <th scope="row" onClick={() => {this.setState({land: v, modal: true}) } }>{v.id}</th>
                    <td>{v.iso}</td>
                    <td>{v.land}</td>
                    <td>{v.EU && <CheckIcon />}</td>
                    <td>
                      <Link to={'/mitglieder/?key=wohnadresse__country__id&value='+v.id}>{v.vereinsmitglied_count} ({v.vereinsmitglied_inactive_count})</Link>
                    </td>
                    <td>
                      <Link to={'/institutionen/?key=wohnadresse__country__id&value='+v.id}>{v.institution_count} ({v.institution_inactive_count})</Link>
                    </td>
                  </tr>)
                )}
              </tbody>
            </Table>
        </Row>
      }
      </Row>
      <FormModal open={this.state.modal} title="Edit Countries" handleClose={this.closeModal}>
        <Form values={this.state.land}
          options={this.props.laenderOptions}
          handleCancel={this.closeModal}
          postUrl={BASE_URL+'countries/'}
          onChange={this.formChange} 
          postSave={this.reload} 
          handleDelete={this.reload} />          
      </FormModal>
    </Container>
  }
}

Laender.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Laender))
