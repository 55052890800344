import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { toast } from 'react-toastify';
 
 
 
 
class BevTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: true,
      order: 'desc',
      orderBy: this.props.columns[0].name,
      filterByName: '',
      data: null,
      rowsPerPage: 10,
      offset: 0,
      page: 0,
      switch: true,
      resultNums: 0,
    }
  }
 
  getData() {
    let url = this.props.url
    if (this.props.location.search) { url += this.props.location.search + '&'} else { url+='?'}
    url+='limit='+this.state.rowsPerPage
    url+='&offset='+this.state.offset;
    url += '&ordering='
    if (this.state.order === 'desc') { url+='-'}
    url += this.state.orderBy;
    if (this.state.filterByName) { url += '&'+this.props.searchFieldName+'='+this.state.filterByName}
    if (this.state.switch) { url += '&'+this.props.switchUrl}
    axios.get(url, this.props.config).then(res => {
      this.setState({ data: res.data.results, resultNums: res.data.count });
    })
  }
 
  componentDidMount() {
    this.getData();
    console.log(this.props.columns)
  }
 
  handleSort = property => event => {
    let ord = 'desc'
    if (this.state.orderBy === property) {
         ord = (this.state.order === 'asc' ? 'desc' : 'asc')
    }
    this.setState({ orderBy: property, order: ord}, this.getData )
  }
 
  changeName = event => {
    this.setState({ filterByName: event.target.value });
  }
 
  handleChangePage = (event, page) => {
    this.setState({page: page, offset: this.state.rowsPerPage*page }, this.getData );
  }
 
  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value }, this.getData );
  }
 
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.getData()
    }
  }

  setSwitchBack = (url) => {
    axios.get(url+'set_bezahlt/', this.props.config).then(res => {
      toast.success(res.data.name + ' hat bezahlt');
      this.getData();
    })
  }
 
 
  render() {
    return <div>
      <Grid container alignItems="center" justify="center" spacing={16}>
        <Grid item xs={12}>
          { this.props.showNewButton && <Button style={{marginTop: '20px', marginBottom: '20px'}} variant="contained" color="secondary" onClick={this.props.new}>{this.props.newText}</Button> }
          
          { 
            this.props.switchButton && <FormControlLabel
              style={{ marginLeft: '30px' }}
              control={ <Switch checked={this.state.switch} onChange={() => this.setState({switch: !this.state.switch}, this.getData )} />}
              label={this.props.switchText} />
          }
 
          <TextField style={{marginRight: '25px', float: 'right', width: '200px'}}
              value={this.state.filterByName}
              onChange={this.changeName}
              onKeyPress={this._handleKeyPress}
              variant="outlined"
              autoFocus={true}
              label="Search" />
 
 
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {this.props.columns.map( (col, key) => (
                    <TableCell key={key}>
                      <Tooltip title="Sort" placement={'bottom-start'} enterDelay={300}>
                        <TableSortLabel active={col.name === this.state.orderBy} direction={this.state.order} onClick={this.handleSort(col.name)}>
                          {col.name}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
 
              <TableBody>
                { this.state.data && (this.state.data.map((row, ii) => (
                    <TableRow key={ii} style={ !row[this.props.deleteField] ? {backgroundColor: 'papayawhip', color: 'red'} : {} }>
                        <TableCell component="th" scope="row">
                          { this.props.detailFunction ? <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}} data={row}
                              onClick={() => (this.props.detailFunction(row))}>
                                {row[this.props.columns[0].name]}
                              </span> : <Link to={{pathname: this.props.detailUrl + row.id}} >
                                {row[this.props.columns[0].name]}
                            </Link>
                          }
                        </TableCell>
                        {this.props.columns.map((v,i) => {
                          if ( i < 1) return;
                          if (v.hasOwnProperty("type")) {
                            return <TableCell key={i} align={row[v.align]}><Switch checked={row[v.name]} onChange={() => this.setSwitchBack(row.url)} /></TableCell>
                          } else {
                            return <TableCell key={i} align={row[v.align]}>{row[v.name]}</TableCell>
                          }

                        })}
                    </TableRow>
                )))
                }
              </TableBody>
            </Table>
            <TablePagination rowsPerPageOptions={[10, 20, 50, 100]} component="div" count={this.state.resultNums} rowsPerPage={this.state.rowsPerPage} page={this.state.page}
              backIconButtonProps={{ 'aria-label': 'Previous Page', }}
              nextIconButtonProps={{ 'aria-label': 'Next Page', }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
 
          </Paper>
        </Grid>
      </Grid>
    </div>
  }
}
 

BevTable.defaultProps = {
  switchButton: false, //soll der switchbutton angezeigt werden
  switchText: 'aktive', // der Text neben dem SwitchButton
  switchUrl: 'aktiv=True', // die Url die durch den SwitchButton angepasst wird
  deleteField: 'aktiv', // das Attribut das steuert ob die Row inaktiv angezeigt wird
  detailFunction: false,
  showNewButton: true,
}

// export default connect(mapStateToProps, mapDispatchToProps)(Table);
export default BevTable;