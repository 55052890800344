import React, { Component } from 'react';
import axios from 'axios';
import * as actions from '../store/actions';
import { BASE_URL } from '../common/constants';
import { Container, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill'; 


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
    setZahlscheinText: (m) => dispatch(actions.base.setZahlscheinText(m)),
    setEmailText: (m) => dispatch(actions.base.setEmailText(m)),
  }
}


const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    zahlscheinText: state.base.zahlscheinText,
    emailText: state.base.emailText,
  }
}

class Finanzen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dash_nums: false,
    }
  }

  componentDidMount() {
    axios.get(BASE_URL + 'dashboard').then(res => {
      this.setState({
        loading: false,
        dash_nums: res.data
      });
    })
    this.props.setTitle("Standardtexte");
  }

  saveZahlschein = (event) => {
    // this.props.setZahlscheinText(event.target.value)
    // console.log(event.target.value)
    this.props.setZahlscheinText(event)
    console.log(event)
  }
  
  saveEmail = (event) => {
    this.props.setEmailText(event.target.value)
    console.log(event.target.value)
  }


  render() {
    return <Container>
      <FormGroup>
        <Label for="zahlscheinText">Text für Zahlschein</Label>
        <ReactQuill theme="snow" value={this.props.zahlscheinText} onChange={this.props.setZahlscheinText} />
      </FormGroup>    


     

      <FormGroup style={{marginTop: '30px'}}>
        <Label for="emailText">Text für Email</Label>
        <ReactQuill theme="snow" value={this.props.emailText} onChange={this.props.setEmailText} />
      </FormGroup>
      
      </Container>
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Finanzen)
