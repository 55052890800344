import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Table } from 'reactstrap';
import * as actions from '../store/actions';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../common/constants';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Container, Row } from 'reactstrap';
import FormModal from '../components/FormModal';
import Form from '../components/Form';
import Loader from '../common/loader';

const styles = theme => ({
  root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  eu_colors: {
    // backgroundColor: 'blue',
    color: 'yellow',
  }
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
    updateBerufe: () => dispatch(actions.base.updateBerufe()),
    updateBerufeOptions: () => dispatch(actions.base.updateBerufeOptions()),
    setBerufe: (cs) => dispatch(actions.base.base.setBerufe(cs)),
  }
}


const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    berufeOptions: state.base.berufeOptions,
    auth: state.auth,
  }
}


class Berufe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      beruf: false,
      berufe: false,
      berufeOptions: false,
    }
  }

  updateBerufe() {
    axios.get(BASE_URL+'berufe/', this.props.auth.config).then(res => {
      this.setState({ berufe: res.data, loading: false }, this.props.updateBerufeOptions() )
    }).catch(err => {
      console.log("so nicht mein freund");
    });
  }

  componentDidMount() {
    this.updateBerufe()
    this.props.setTitle("Berufsgruppen")
  }

  formChange = event => {
    let cs = this.state.beruf;
    cs[event.target.name] = event.target.value;
    this.setState({ beruf: cs});
  }

  closeModal = () => {
    this.setState({modal:false});
  }

  reload = () => {
    axios.get(BASE_URL + 'berufe', this.props.auth.config).then(res => {
      this.setState({ loading: false, berufe: res.data, });
    });
  }

  render() {
    return <Container><Row>
      { this.state.loading ? <h2>Daten werden geladen...</h2> :   <Row>
            <Button color="primary"
                onClick={ () => { this.setState({modal:true, kost: {url: 'new'}}) } }
              >Neue Berufsgruppe anlegen</Button>
            <Table>
              <thead>
                <tr>
                  <th>id</th>
                  <th>beruf</th>
                  <th>bezeichnung</th>
                  <th>mitglieder</th>
                  <th>institutionen</th>
                </tr>
              </thead>
              <tbody>
                {this.state.berufe.results.map((v,i) => (<tr key={v.id}>
                    <th scope="row" onClick={() => {console.log("vau");console.log(v);this.setState({beruf: v, modal: true}) } }>{v.id}</th>
                    <td>{v.beruf}</td>
                    <td>{v.bezeichnung}</td>
                    <td>
                      <Link to={'/mitglieder/?key=berufsgruppe__beruf&value='+v.beruf}>{v.vereinsmitglied_count} ({v.vereinsmitglied_inactive_count})</Link>
                    </td>
                    <td>
                      <Link to={'/institutionen/?key=berufsgruppe__beruf&value='+v.beruf}>{v.institution_count} ({v.institution_inactive_count})</Link>
                    </td>
                  </tr>)
                )}
              </tbody>
            </Table>
        </Row>
      }
      </Row>
      <FormModal open={this.state.modal} title="Edit Adresse" handleClose={this.closeModal}>
        <Form values={this.state.beruf}
          options={this.props.berufeOptions}
          handleCancel={this.closeModal}
          postUrl={BASE_URL+'berufe/'}
          onChange={this.formChange} 
          postSave={this.reload} 
          handleDelete={this.reload} />          
      </FormModal>
      </Container>
    }

  render2() {
    return <Container><Row>
      {this.state.editing ? <Form values={this.state.berufe.results[this.state.id]}
            options={this.state.berufeOptions}
            handleCancel={() => this.setState({editing: false})}
            onChange={this.formChange} /> : <div>
        { this.state.loading ? <Loader /> : <List>{this.state.berufe.results.map((p,i) => (
            <ListItem key={i} button onClick={() => {this.setState({id: i, editing: true})}}>
                <ListItemIcon className={this.props.classes.eu_colors}>
                  <Avatar>{p.beruf[0]}</Avatar>
                </ListItemIcon>
                <ListItemText primary={p.bezeichnung} secondary={p.beruf} />
            </ListItem>
          ))}
        </List>
      }</div>}
    </Row></Container>
  }
}

Berufe.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Berufe))
