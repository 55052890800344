import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import UserIcon from '@material-ui/icons/AccountCircleRounded';
import SettingsIcon from '@material-ui/icons/SettingsApplicationsRounded';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
import * as actions from '../store/actions';


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      setTitle: (title) => dispatch(actions.base.setTitle(title)),
      loadUser: () => dispatch(actions.auth.loadUser()),
      hasPermission: (groupname) => actions.auth.hasPermission(groupname),
      logout: () => dispatch(actions.auth.logout()),
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      title: state.base.title,
      auth: state.auth,
    }
  }


class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        }
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <UserIcon />&nbsp;{this.props.username}

        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}><SettingsIcon />&nbsp;My account</MenuItem>
          <MenuItem onClick={this.props.logout}><LogoutIcon />&nbsp;Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
