import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import * as constants from '../common/constants';
import { Container } from 'reactstrap';
import BevTable from '../components/Table';


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (title) => dispatch(actions.base.setTitle(title)),
  }
}


const mapStateToProps = (state) => {
  return {
    config: state.auth.config,
  }
}

class Institutionen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      members: false,
      offset: 0,
      pagination: 50,
      wer: '',
      aktive: true,
    }
  }

  componentDidMount() {
    this.props.setTitle("Institutionen")
  }

  addMember = () => {
    this.props.history.push('/institution/new')
  }

  columns =  [
      {name: "mitgliedsnummer", align: "left"},
      {name: "institution_name", align: "left"},
      {name: "email", align: "left"},
  ];



  render() {
    return <Container>
              <BevTable titel="Institutionen" 
                url={constants.BASE_URL + "institutionen/"} 
                detailUrl='/institution/' 
                location={this.props.location}
                newText='neuen Institutionen anlegen'
                new={this.addMember}
                searchFieldName='namefilter' 
                config={this.props.config}
                switchButton={true}
                switchText={'nur aktive Institutionen'}
                switchUrl='aktiv=True'
                columns={this.columns} />
            </Container>
  }

}



Institutionen.defaultProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Institutionen);
