import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import * as actions from "../store/actions";
import { BASE_URL } from "../common/constants";
import { Container } from "reactstrap";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setTitle: (m) => dispatch(actions.base.setTitle(m)),
  };
};

const mapStateToProps = (state) => {
  return {
    title: state.base.title,
    config: state.auth.config,
  };
};

class Etiketten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jahr: "",
      warten: false,
    };
  }

  componentDidMount() {
    this.props.setTitle("Etiketten ");
  }

  get_plz = () => {
    axios
      .get(
        BASE_URL + "abonnent/etiketten/?wohin=AUT&plz=True",
        this.props.config
      )
      .then((res) => {
        const file = new Blob([res.data], { type: "application/txt" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  get_etiketten = (wohin) => {
    axios
      .get(BASE_URL + "abonnent/etiketten/?wohin=" + wohin, this.props.config)
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  render() {
    return (
      <Container>
        <h2> Hier werden die Etiketten erstellt</h2>
        <Button onClick={() => this.get_etiketten("BEV")}>nur BEV</Button>
        <Button onClick={() => this.get_etiketten("AUT")}>Österreich</Button>
        <Button onClick={() => this.get_plz()}>Österreich - PLZ</Button>
        <Button onClick={() => this.get_etiketten("INT")}>International</Button>
      </Container>
    );
  }
}

Etiketten.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Etiketten));
