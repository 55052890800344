import { put } from 'redux-saga/effects';
import axios from 'axios';
import store from '../../store';

import * as actions from '../actions/base';
import { BASE_URL } from '../../common/constants';
import { toast } from 'react-toastify';



export function* updateKostenOptions() {
  let config = yield store.getState().auth.config;
  
  const res = yield axios.options(BASE_URL+'kosten/', config);
  if (res.status === 200) {
    yield put(actions.setKostenOptions(res.data.actions.POST));
  }
}


export function* updateCountriesOptions() {
  let config = yield store.getState().auth.config;

  yield put(actions.setCountriesLoaded(false))

  try {
    const ares = yield axios.get(BASE_URL+'countries/', config);
    if (ares.status === 200) {
      yield put(actions.setCountries(ares.data.results));
    } else {
      yield toast.error("setCountries error");
    }
  } catch (err) {
    yield toast.error("binsn");

  }
  
  const ores = yield axios.options(BASE_URL+'countries/',config);
  if (ores.status === 200) {
    yield put(actions.setCountriesOptions(ores.data.actions.POST));
    yield put(actions.setCountriesLoaded(true));
  } else {
    alert("setCountriesOptions error");
  }

  const res = yield axios.options(BASE_URL+'adresse/', config);
  if (res.status === 200) {
    yield put(actions.setAdresseOptions(res.data.actions.POST));
  }
}



export function* updateOffenePostenOptions() {
  let config = yield store.getState().auth.config;
  
  const res = yield axios.options(BASE_URL+'offeneposten/', config);
  if (res.status === 200) {
    yield put(actions.setOffenePostenOptions(res.data.actions.POST));
  }
}



export function* updateInstitutionOptions() {
  let config = yield store.getState().auth.config;

  const res = yield axios.options(BASE_URL+'institutionen/', config);
  if (res.status === 200) {
    yield put(actions.setInstitutionOptions(res.data.actions.POST));
  }
  yield put(actions.updateCountriesSaga());
}



export function* updateVortragOptions() {
  let config = yield store.getState().auth.config;

  yield put(actions.setVortragLoaded(false))
    
  const res = yield axios.get(BASE_URL+'vortragsort/',config);
  if (res.status === 200) {
    let orte = res.data.results;
      orte.map((ort) => {
        ort.type = 'boolean'
        ort.label = ort.Vortragsort
        return ort
      })
    yield put(actions.setVortragsorte(orte));
    yield put(actions.setVortragLoaded(true));
  } else {
    alert("setCountriesOptions error");
  }
}



export function* updateAbonnentOptions() {
  let config = yield store.getState().auth.config;

  const res = yield axios.options(BASE_URL+'abonnent/', config);
  if (res.status === 200) {
    yield put(actions.setAbonnentOptions(res.data.actions.POST));
  }

  const ares = yield axios.options(BASE_URL+'aboheft/', config);
  if (ares.status === 200) {
    yield put(actions.setAboHeftOptions(ares.data.actions.POST));
  }
}


export function* updateAboHeftOptions() {
  console.log("aha")
  let config = yield store.getState().auth.config;
  const res = yield axios.options(BASE_URL+'aboheft/', config);
  if (res.status === 200) {
    console.log(res.data)
    yield put(actions.setAboHeftOptions(res.data.actions.POST));
  }
}

export function* updateOffeneAboPostenOptions() {
  let config = yield store.getState().auth.config;
  const res = yield axios.options(BASE_URL+'offeneaboposten/', config);
  if (res.status === 200) {
    console.log(res.data)
    yield put(actions.setOffeneAboPostenOptions(res.data.actions.POST));
  }
}




export function* updateMemberOptions() {
  let config = yield store.getState().auth.config;
  
  const res = yield axios.options(BASE_URL + "vereinsmitglieder/", config);
  if (res.status === 200 ) {
    yield put(actions.updateMemberOptions(res.data.actions.POST));
  } else {
    // Ansonsten ist der Server nicht erreichbar!!!
  }

  yield put(actions.updateCountriesSaga());
  yield put(actions.updateVortragsorteSaga());
  yield put(actions.updateOffenePostenOptions());

}
